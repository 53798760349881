@charset "UTF-8";
/**
 * Сброс стилей
 **/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Source+Sans+Pro:300,400,600,700&subset=cyrillic");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

input {
  outline: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

/**
 * Основные стили
 **/
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px; }

.container {
  min-width: 320px;
  max-width: 100%;
  padding: 0 0;
  box-sizing: border-box;
  margin: 0 auto; }

.wrap {
  min-width: 320px;
  max-width: 1200px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto; }

p {
  color: #000; }

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  25% {
    transform: translate(-50%, -35%) rotate(-45deg); }
  50% {
    transform: translate(-50%, -50%) rotate(0deg); }
  75% {
    transform: translate(-50%, -35%) rotate(45deg); }
  100% {
    transform: translate(-50%, -50%) rotate(0deg); } }

.section-top {
  background: url("../img/section-top-bg.jpg") top center no-repeat;
  background-size: cover;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 90%, 0 80%); }
  .section-top::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
  .section-top .section-top__header {
    position: relative;
    z-index: 2;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .section-top .section-top__menu {
    flex-basis: 62.5%; }
  .section-top .section-top__info {
    padding: 180px 0 300px;
    text-align: center;
    position: relative;
    z-index: 2; }
    .section-top .section-top__info h1 {
      font-size: 24px;
      letter-spacing: 1.2px;
      color: #f4f5f9;
      font-family: 'Source Sans Pro'; }
      .section-top .section-top__info h1 span {
        font-size: 72px;
        font-weight: 700;
        font-family: 'Open Sans';
        text-transform: uppercase;
        letter-spacing: 7.2px;
        margin: 30px 0 20px;
        display: block; }
    .section-top .section-top__info > p {
      max-width: 600px;
      margin: 20px auto;
      font-size: 14px;
      line-height: 24px;
      color: #cbc6c0; }

@media (max-width: 980px) {
  .section-top .section-top__info {
    padding-top: 50px; }
    .section-top .section-top__info h1 span {
      font-size: 42px; } }

@media (max-width: 768px) {
  .section-top .section-top__header {
    flex-wrap: wrap; }
  .section-top .section-top__logo {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 30px; }
  .section-top .section-top__menu {
    flex-basis: 100%; }
  .section-top .section-top__info > p {
    max-width: 100%; } }

.menu ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .menu ul li {
    margin: 0 5px; }
  .menu ul a {
    color: #f4f5f9;
    text-decoration: none;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    padding-bottom: 17px;
    border-bottom: 1px solid transparent;
    transition-duration: 300ms;
    transition-property: color, border-bottom-color; }
    .menu ul a:hover {
      color: #e74c3c;
      border-bottom-color: #e74c3c; }

@media (max-width: 768px) {
  .menu ul li {
    margin: 5px; }
  .menu ul a {
    padding-bottom: 0;
    border-bottom: none; }
    .menu ul a:hover {
      border-bottom: none; } }

@media (max-width: 560px) {
  .menu ul {
    justify-content: center; } }

.buttons {
  margin-top: 60px; }
  .buttons a {
    width: 225px;
    border-radius: 3px;
    background-color: #e74c3c;
    border: 2px solid #e74c3c;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    padding: 18px 0;
    letter-spacing: 0.14px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    box-sizing: border-box;
    transition-duration: 500ms;
    transition-property: background-color, border-color; }
    .buttons a:hover {
      background-color: #d62c1a;
      border-color: #d62c1a; }
    .buttons a:first-child {
      margin-right: 25px; }
    .buttons a:last-child {
      background-color: transparent;
      border-color: #fff; }
    .buttons a:hover:last-child {
      border-color: #e74c3c; }

@media (max-width: 560px) {
  .buttons a {
    display: block;
    margin: 10px auto; }
    .buttons a:first-child {
      margin: 15px auto; } }

.devider {
  display: block;
  width: 7px;
  position: relative;
  margin: 20px auto; }
  .devider p {
    text-align: center; }
    .devider p::after {
      content: "";
      width: 7px;
      height: 7px;
      background-color: #e74c3c;
      display: block;
      border-radius: 50%; }
  .devider::after, .devider::before {
    content: "";
    width: 80px;
    height: 1px;
    background-color: #f4f5f9;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-1px); }
  .devider::before {
    left: -90px; }
  .devider::after {
    right: -90px; }

.section-wrap {
  position: relative; }
  .section-wrap .section-wrap__icon a {
    width: 64px;
    height: 64px;
    border: 5px solid rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 50%;
    bottom: 8%;
    transform: translate(-50%, 15px);
    z-index: 2;
    border-radius: 50%;
    background-color: #f4f5f9; }
    .section-wrap .section-wrap__icon a:hover .section-wrap__img {
      animation-name: rotate;
      animation-duration: 2s;
      animation-iteration-count: infinite; }
  .section-wrap .section-wrap__icon .section-wrap__img {
    width: 30px;
    height: 30px;
    fill: #e74c3c;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.advantages {
  border-bottom: 1px solid #e6e7eb;
  padding-bottom: 60px;
  margin-bottom: 140px; }
  .advantages .advantages__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .advantages .advantages__element {
    flex-basis: 23.5%;
    margin: 20px 0;
    text-align: center; }
    .advantages .advantages__element p {
      font-family: "Source Sans Pro";
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.9px;
      margin: 15px 0;
      color: #999999; }
    .advantages .advantages__element .advantages__title {
      font-family: "Open Sans";
      font-weight: 600;
      text-transform: uppercase;
      color: #6a6a6a;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 1.35px;
      transition-duration: 500ms;
      transition-property: color; }
      .advantages .advantages__element .advantages__title::after {
        content: "";
        width: 41px;
        height: 1px;
        background-color: #888888;
        border-bottom: 1px solid #e1e1e1;
        display: block;
        margin: 15px auto; }
    .advantages .advantages__element:hover .advantages__icon {
      transform: rotate(45deg);
      border-color: #e74c3c; }
      .advantages .advantages__element:hover .advantages__icon img, .advantages .advantages__element:hover .advantages__icon svg {
        transform: translate(-50%, -50%) rotate(-45deg); }
    .advantages .advantages__element:hover .advantages__title {
      color: #e74c3c; }
  .advantages .advantages__icon {
    width: 73px;
    height: 73px;
    border: 1px solid #e5e5e5;
    position: relative;
    margin: 20px auto;
    transition-duration: 500ms;
    transition-property: border-color, transform; }
    .advantages .advantages__icon img, .advantages .advantages__icon svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      fill: #e74c3c;
      transition-duration: 500ms;
      transition-property: transform; }
    .advantages .advantages__icon .advantages__img1 {
      width: 32px;
      height: 12px; }
    .advantages .advantages__icon .advantages__img2 {
      width: 27px;
      height: 23px; }
    .advantages .advantages__icon .advantages__img3 {
      width: 30px;
      height: 35px; }
    .advantages .advantages__icon .advantages__img4 {
      width: 25px;
      height: 25px; }

@media (max-width: 980px) {
  .advantages {
    padding-bottom: 50px;
    margin-bottom: 50px; } }

@media (max-width: 768px) {
  .advantages .advantages__element {
    flex-basis: 49%; } }

@media (max-width: 480px) {
  .advantages .advantages__element {
    flex-basis: 100%; } }

.history {
  display: flex;
  justify-content: space-between;
  margin: 140px auto; }
  .history .history__left {
    flex-basis: 43.2%;
    margin-right: 80px; }
    .history .history__left img {
      display: block;
      max-width: 100%; }
  .history .history__right {
    flex-basis: calc(100% - 43.2% - 80px); }
    .history .history__right .history__title {
      color: #6a6a6a;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      font-weight: 700;
      padding-left: 25px;
      border-left: 6px solid #e74c3c;
      margin-top: 0;
      margin-bottom: 30px; }
    .history .history__right p {
      font-size: 14px;
      line-height: 29px;
      color: #999999;
      font-family: "Source Sans Pro";
      margin: 25px 0; }
    .history .history__right a {
      width: 225px;
      border-radius: 3px;
      background-color: #e74c3c;
      border: 2px solid #e74c3c;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      padding: 18px 0;
      letter-spacing: 0.14px;
      text-decoration: none;
      display: inline-block;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      box-sizing: border-box;
      transition-duration: 500ms;
      transition-property: background-color, border-color;
      margin-top: 20px; }
      .history .history__right a:hover {
        background-color: #d62c1a;
        border-color: #d62c1a; }

@media (max-width: 980px) {
  .history {
    margin: 50px 0; }
    .history .history__left {
      margin-right: 25px;
      align-self: center; }
    .history .history__right {
      flex-basis: calc(100% - 43.2% - 25px); } }

@media (max-width: 680px) {
  .history {
    flex-direction: column; }
    .history .history__left {
      margin-right: 0;
      margin-bottom: 30px; } }

.service {
  background-color: #202020;
  max-width: 1920px;
  margin: 140px auto;
  padding: 150px 0;
  position: relative; }
  .service::after {
    content: "";
    width: 46%;
    height: 100%;
    display: block;
    background: url("../img/services-bg.jpg") right top no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0; }
  .service .service__block {
    width: 54%;
    text-align: right; }
  .service .service__title {
    font-size: 36px;
    line-height: 36px;
    color: #f4f5f9;
    letter-spacing: 3.6px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Source Sans Pro";
    padding-right: 25px;
    border-right: 6px solid #e74c3c;
    margin-right: 80px;
    margin-bottom: 30px; }
  .service .service__element {
    max-width: 665px;
    margin: 55px 80px 55px auto;
    position: relative; }
    .service .service__element:last-child {
      margin-bottom: 0; }
    .service .service__element p {
      font-size: 14px;
      line-height: 29px;
      color: #999999;
      font-family: "Source Sans Pro"; }
      .service .service__element p:first-of-type {
        text-transform: uppercase;
        color: #e74c3c;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 18px; }
    .service .service__element .service__icon {
      width: 64px;
      height: 64px;
      border: 5px solid rgba(0, 0, 0, 0.15);
      position: absolute;
      right: -117px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 1;
      border-radius: 50%;
      background-color: #f4f5f9; }
      .service .service__element .service__icon img, .service .service__element .service__icon svg {
        fill: #e74c3c;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
    .service .service__element .service__img {
      width: 27px;
      height: 27px; }
    .service .service__element .service__img2 {
      width: 35px;
      height: 29px; }
    .service .service__element .service__img3 {
      width: 35px;
      height: 30px; }

@media (max-width: 980px) {
  .service {
    margin: 50px 0;
    padding: 50px 0; } }

@media (max-width: 768px) {
  .service {
    padding-left: 15px;
    padding-right: 15px; }
    .service::after {
      display: none; }
    .service .service__block {
      width: 100%;
      text-align: left; }
    .service .service__title {
      padding-right: 0;
      padding-left: 25px;
      border-right: none;
      border-left: 6px solid #e74c3c; }
    .service .service__element {
      max-width: 100%;
      margin: 25px 0;
      padding-right: 80px;
      box-sizing: border-box; }
      .service .service__element .service__icon {
        right: 0; } }

.recent {
  margin-top: 140px; }
  .recent > p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #999999; }
  .recent .recent__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .recent .recent__devider p {
      text-align: center; }
      .recent .recent__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #e74c3c;
        display: block;
        border-radius: 50%; }
    .recent .recent__devider::after, .recent .recent__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .recent .recent__devider::before {
      left: -90px; }
    .recent .recent__devider::after {
      right: -90px; }
  .recent .recent__title {
    color: #6a6a6a;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .recent .recent__links {
    margin: 40px 0;
    text-align: center;
    color: #999999; }
    .recent .recent__links a {
      text-decoration: none;
      font-size: 14px;
      color: #999999;
      display: inline-block;
      margin: 5px 19px;
      transition-duration: 500ms;
      transition-property: color; }
      .recent .recent__links a:hover {
        color: #e74c3c; }
  .recent .recent__block {
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 40px auto 0; }
  .recent .recent__element {
    flex-basis: 25%;
    position: relative; }
    .recent .recent__element img {
      display: block;
      max-width: 100%; }
    .recent .recent__element a {
      text-decoration: none;
      display: block; }
      .recent .recent__element a::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(231, 76, 60, 0.8);
        opacity: 0;
        transition-duration: 500ms;
        transition-property: opacity; }
      .recent .recent__element a:hover::after, .recent .recent__element a:hover p {
        opacity: 1; }
    .recent .recent__element p {
      position: absolute;
      left: 10%;
      bottom: 35px;
      z-index: 1;
      color: #fff;
      opacity: 0;
      transition-duration: 500ms;
      transition-property: opacity; }
      .recent .recent__element p::before {
        content: "";
        width: 44px;
        height: 4px;
        background-color: #fff;
        margin-bottom: 25px;
        display: block; }
      .recent .recent__element p span {
        display: block;
        margin: 15px 0;
        font-size: 14px;
        letter-spacing: 0.7px; }
        .recent .recent__element p span:first-child {
          text-transform: uppercase;
          font-family: "Source Sans Pro";
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 1.2px; }

@media (max-width: 980px) {
  .recent {
    margin-top: 50px; }
    .recent .recent__element p {
      bottom: 10px; }
      .recent .recent__element p span:first-child {
        font-size: 18px; } }

@media (max-width: 768px) {
  .recent .recent__element {
    flex-basis: 50%; } }

@media (max-width: 480px) {
  .recent .recent__element {
    flex-basis: 100%; } }

.case-study {
  background-color: #f4f5f9;
  padding: 140px 0; }
  .case-study p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #999999; }
  .case-study .case-study__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .case-study .case-study__devider p {
      text-align: center; }
      .case-study .case-study__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #e74c3c;
        display: block;
        border-radius: 50%; }
    .case-study .case-study__devider::after, .case-study .case-study__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .case-study .case-study__devider::before {
      left: -90px; }
    .case-study .case-study__devider::after {
      right: -90px; }
  .case-study .case-study__title {
    color: #6a6a6a;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .case-study .case-study__block {
    display: flex;
    align-items: center;
    margin-top: 60px; }
  .case-study .case-study__left {
    flex-basis: 665px;
    position: relative;
    z-index: 1; }
  .case-study .case-study__right img {
    margin-left: -80px; }

.ideas {
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  padding: 30px 50px 80px;
  box-sizing: border-box; }
  .ideas .ideas__icon {
    width: 127px;
    height: 127px;
    background-color: #e74c3c;
    margin: 50px auto;
    position: relative;
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%); }
    .ideas .ideas__icon img, .ideas .ideas__icon svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .ideas .ideas__icon .ideas__img {
      fill: #fff;
      width: 35px;
      height: 40px; }
  .ideas .ideas__title {
    font-size: 18px;
    font-weight: 600;
    color: #6a6a6a;
    letter-spacing: 1.35px;
    text-transform: uppercase; }
    .ideas .ideas__title::after {
      content: "";
      width: 41px;
      height: 1px;
      background-color: #888888;
      border-bottom: 1px solid #e1e1e1;
      display: block;
      margin: 20px auto 0; }
  .ideas p {
    font-size: 14px;
    line-height: 24px;
    color: #999999;
    font-family: "Source Sans Pro";
    font-weight: 300;
    letter-spacing: 1.05px; }
  .ideas .ideas__btn {
    width: 225px;
    border-radius: 3px;
    background-color: #e74c3c;
    border: 2px solid #e74c3c;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    padding: 18px 0;
    letter-spacing: 0.14px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    box-sizing: border-box;
    transition-duration: 500ms;
    transition-property: background-color, border-color;
    width: 145px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.12px;
    padding: 13px 0;
    display: block;
    margin: 30px auto;
    font-weight: 600; }
    .ideas .ideas__btn:hover {
      background-color: #d62c1a;
      border-color: #d62c1a; }

@media (max-width: 980px) {
  .case-study {
    padding: 50px 0; }
    .case-study .case-study__left {
      flex-basis: 50%;
      padding: 30px; }
    .case-study .case-study__right {
      flex-basis: 50%; }
      .case-study .case-study__right img {
        max-width: 100%;
        margin-left: 0; } }

@media (max-width: 768px) {
  .case-study .case-study__block {
    flex-direction: column; }
  .case-study .case-study__left {
    padding: 20px; }
  .case-study .case-study__right {
    margin-top: 30px; } }

.counts {
  background: url("../img/counts-bg.jpg") center no-repeat;
  background-size: cover;
  padding: 120px 0 110px;
  position: relative; }
  .counts::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.5); }
  .counts .counts__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1; }
  .counts .counts__element {
    flex-basis: 20%;
    text-align: center;
    margin: 20px 0; }
    .counts .counts__element .counts__circle {
      width: 109px;
      height: 109px;
      border: 1px solid #fff;
      border-radius: 50%;
      margin: 0 auto 40px;
      position: relative; }
      .counts .counts__element .counts__circle img, .counts .counts__element .counts__circle svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        fill: #fff; }
    .counts .counts__element .counts__img {
      width: 37px;
      height: 32px; }
    .counts .counts__element .counts__img2 {
      width: 40px;
      height: 40px; }
    .counts .counts__element .counts__img3 {
      width: 35px;
      height: 40px; }
    .counts .counts__element .counts__img4 {
      width: 35px;
      height: 35px; }
    .counts .counts__element .counts__img5 {
      width: 35px;
      height: 35px; }
    .counts .counts__element p {
      color: #f4f5f9;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.9px;
      font-family: "Source Sans Pro";
      margin: 10px 0; }
    .counts .counts__element .counts__number {
      font-weight: 700;
      letter-spacing: 1.5px;
      font-size: 30px; }

@media (max-width: 980px) {
  .counts {
    padding: 50px 0; } }

@media (max-width: 768px) {
  .counts .counts__block {
    justify-content: center; }
  .counts .counts__element {
    flex-basis: 45%; } }

@media (max-width: 480px) {
  .counts .counts__element {
    flex-basis: 100%; } }

.price {
  margin: 140px 0; }
  .price p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #999999; }
  .price .price__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .price .price__devider p {
      text-align: center; }
      .price .price__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #e74c3c;
        display: block;
        border-radius: 50%; }
    .price .price__devider::after, .price .price__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .price .price__devider::before {
      left: -90px; }
    .price .price__devider::after {
      right: -90px; }
  .price .price__title {
    color: #6a6a6a;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .price .price__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px; }
  .price .price__element {
    flex-basis: 32.4%;
    margin-top: 20px;
    background-color: #f4f5f9; }
    .price .price__element:hover .price__top {
      background-color: #e74c3c; }
    .price .price__element:hover .price__bottom .price__number p {
      color: #e74c3c; }
    .price .price__element .price__top {
      background-color: #999999;
      padding-bottom: 90px;
      transition-duration: 500ms;
      transition-property: background-color; }
      .price .price__element .price__top .price__name {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        color: #fff;
        margin: 0;
        padding: 40px 0; }
    .price .price__element .price__bottom {
      padding-bottom: 20px; }
      .price .price__element .price__bottom .price__number {
        width: 153px;
        background-color: #fff;
        border: 10px solid rgba(244, 245, 249, 0.25);
        border-radius: 50%;
        margin: -86px auto 0;
        padding: 46px 0; }
        .price .price__element .price__bottom .price__number p {
          font-size: 39px;
          font-family: "Source Sans Pro";
          font-weight: 700;
          line-height: 40px;
          margin: 0;
          color: #999999;
          transition-duration: 500ms;
          transition-property: color; }
          .price .price__element .price__bottom .price__number p span {
            display: block;
            font-size: 13px;
            line-height: 21px;
            font-weight: 400;
            color: #999999; }
      .price .price__element .price__bottom .price__list {
        text-align: center;
        margin: 40px 0; }
        .price .price__element .price__bottom .price__list li {
          font-size: 14px;
          line-height: 20px;
          margin: 20px 0;
          font-weight: 600;
          color: #999999; }
      .price .price__element .price__bottom .price__btn {
        width: 225px;
        border-radius: 3px;
        background-color: #999999;
        border: 2px solid #999999;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        padding: 18px 0;
        letter-spacing: 0.14px;
        text-decoration: none;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        box-sizing: border-box;
        transition-duration: 500ms;
        transition-property: background-color, border-color;
        width: 145px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.12px;
        padding: 13px 0;
        display: block;
        margin: 30px auto;
        font-weight: 600; }
        .price .price__element .price__bottom .price__btn:hover {
          background-color: #d62c1a;
          border-color: #d62c1a; }
  .price .price__element--red .price__top {
    background-color: #e74c3c; }
  .price .price__element--red .price__bottom .price__number p {
    color: #e74c3c; }
  .price .price__element--red .price__bottom .price__btn {
    background-color: #e74c3c;
    border-color: #e74c3c; }

@media (max-width: 980px) {
  .price {
    margin: 50px 0; } }

@media (max-width: 768px) {
  .price .price__block {
    justify-content: center; }
  .price .price__element {
    flex-basis: 45%;
    margin: 20px 2.5%; } }

@media (max-width: 580px) {
  .price .price__element {
    flex-basis: 100%;
    margin: 15px 0; } }

.team {
  margin-top: 140px;
  padding: 140px 0 470px;
  background: url("../img/team-line.png") center bottom no-repeat, url("../img/team-bg.jpg") center no-repeat; }
  .team .team__wrap {
    position: relative; }
  .team p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #f4f5f9; }
  .team .team__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .team .team__devider p {
      text-align: center; }
      .team .team__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #e74c3c;
        display: block;
        border-radius: 50%; }
    .team .team__devider::after, .team .team__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .team .team__devider::before {
      left: -90px; }
    .team .team__devider::after {
      right: -90px; }
  .team .team__title {
    color: #fff;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .team .team__man {
    position: absolute;
    width: 200px; }
    .team .team__man:hover::after {
      opacity: 1; }
    .team .team__man:hover .team__img {
      transform: scale(1.3); }
      .team .team__man:hover .team__img::after {
        opacity: 1; }
    .team .team__man:hover .team__info {
      z-index: 1;
      opacity: 1; }
    .team .team__man::after {
      content: "";
      width: 1px;
      height: 50px;
      display: block;
      background-color: #fff;
      position: absolute;
      left: 50%;
      bottom: -50px;
      transform: translate(-50%);
      opacity: 0;
      z-index: 1;
      transition-duration: 500ms;
      transition-property: opacity; }
    .team .team__man:nth-of-type(1) {
      left: 0;
      bottom: -230px; }
    .team .team__man:nth-of-type(2) {
      left: 265px;
      bottom: -195px; }
    .team .team__man:nth-of-type(3) {
      left: 530px;
      bottom: -195px; }
    .team .team__man:nth-of-type(4) {
      right: 190px;
      bottom: -230px; }
    .team .team__man:nth-of-type(5) {
      right: 0px;
      bottom: -205px; }
  .team .team__img {
    width: 51px;
    height: 51px;
    border: 3px solid #e74c3c;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 500ms;
    transition-property: transform;
    z-index: 2;
    position: relative; }
    .team .team__img::after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      background-color: rgba(231, 76, 60, 0.5);
      opacity: 0;
      transition-duration: 500ms;
      transition-property: opacity; }
    .team .team__img img {
      display: block;
      max-width: 100%; }
  .team .team__info {
    position: absolute;
    bottom: -160px;
    width: 100%;
    opacity: 0;
    z-index: -1;
    transition-duration: 500ms;
    transition-property: opacity; }
    .team .team__info p {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1.4px;
      margin: 10px 0;
      font-family: "Source Sans Pro"; }
      .team .team__info p:first-of-type {
        font-size: 24px;
        line-height: 24px;
        color: #e74c3c;
        font-family: "Open Sans";
        font-weight: 600;
        letter-spacing: 1.8px; }

@media (max-width: 1080px) {
  .team {
    background: url("../img/team-bg.jpg") center no-repeat;
    background-size: cover;
    padding: 140px 0; }
    .team .team__elements {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
    .team .team__man {
      position: relative;
      left: auto !important;
      right: auto !important;
      bottom: auto !important;
      width: auto;
      flex-basis: 20%;
      margin: 25px 0; }
      .team .team__man::after {
        display: none; }
    .team .team__info {
      position: static;
      opacity: 1; } }

@media (max-width: 980px) {
  .team {
    padding: 50px 0;
    margin-top: 50px; } }

@media (max-width: 768px) {
  .team .team__elements {
    justify-content: center; }
  .team .team__man {
    flex-basis: 45%; } }

@media (max-width: 480px) {
  .team .team__man {
    flex-basis: 100%; } }

.integrations {
  background-color: #f4f5f9;
  padding: 140px 0; }
  .integrations p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #999999; }
  .integrations .integrations__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .integrations .integrations__devider p {
      text-align: center; }
      .integrations .integrations__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #e74c3c;
        display: block;
        border-radius: 50%; }
    .integrations .integrations__devider::after, .integrations .integrations__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .integrations .integrations__devider::before {
      left: -90px; }
    .integrations .integrations__devider::after {
      right: -90px; }
  .integrations .integrations__title {
    color: #6a6a6a;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .integrations .integrations__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px; }
  .integrations .intagrations__element {
    flex-basis: 18%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(31, 31, 31, 0.09);
    margin: 15px 0;
    height: 100px;
    display: flex;
    justify-content: center; }
    .integrations .intagrations__element span {
      align-self: center; }
    .integrations .intagrations__element img {
      max-width: 100%; }

@media (max-width: 980px) {
  .integrations {
    padding: 50px 0; }
    .integrations .integrations__block {
      justify-content: center; }
    .integrations .intagrations__element {
      flex-basis: 30%;
      margin: 15px 1.5%; } }

@media (max-width: 580px) {
  .integrations .intagrations__element {
    flex-basis: 45%;
    margin: 15px 2.5%; } }

@media (max-width: 480px) {
  .integrations .intagrations__element {
    flex-basis: 100%;
    margin: 10px 0; } }

.blog {
  background-color: #f4f5f9;
  padding: 140px 0 0;
  border-top: 1px solid #d8d9db; }
  .blog p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #999999; }
  .blog .blog__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .blog .blog__devider p {
      text-align: center; }
      .blog .blog__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #e74c3c;
        display: block;
        border-radius: 50%; }
    .blog .blog__devider::after, .blog .blog__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .blog .blog__devider::before {
      left: -90px; }
    .blog .blog__devider::after {
      right: -90px; }
  .blog .blog__title {
    color: #6a6a6a;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .blog .blog__block {
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 60px auto 0; }
  .blog .blog__element {
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap; }
  .blog .blog__element--reverse .blog__img {
    order: 2; }
  .blog .blog__element--reverse .blog__text {
    order: 1; }
    .blog .blog__element--reverse .blog__text::before {
      border-right: 30px solid transparent;
      border-left: 25px solid #e74c3c;
      left: auto;
      right: -55px; }
  .blog .blog__img {
    flex-basis: 50%; }
    .blog .blog__img img {
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: cover; }
  .blog .blog__text {
    flex-basis: 50%;
    padding: 25px 25px 25px 40px;
    background-color: #e74c3c;
    box-sizing: border-box;
    position: relative; }
    .blog .blog__text::before {
      content: "";
      border: 30px solid transparent;
      border-right: 25px solid #e74c3c;
      position: absolute;
      top: 50%;
      left: -55px;
      transform: translateY(-50%); }
    .blog .blog__text p {
      font-size: 16px;
      line-height: 30px;
      color: #fff;
      text-align: left;
      margin: 15px 0; }
    .blog .blog__text .blog__category {
      font-size: 14px;
      letter-spacing: 0.7px; }
    .blog .blog__text .blog__name {
      font-size: 24px;
      letter-spacing: 1.2px;
      font-family: "Source Sans Pro";
      font-weight: 600; }
      .blog .blog__text .blog__name::after {
        content: "";
        width: 44px;
        height: 4px;
        background-color: #fff;
        display: block;
        margin-top: 15px; }
    .blog .blog__text a {
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      font-style: italic;
      margin: 20px 0;
      display: inline-block; }
      .blog .blog__text a:hover {
        text-decoration: underline; }

@media (max-width: 1200px) {
  .blog .blog__text {
    padding: 20px 15px; } }

@media (max-width: 980px) {
  .blog {
    padding: 50px 0 0; }
    .blog .blog__element {
      flex-basis: 100%; }
      .blog .blog__element .blog__img img {
        width: 100%; }
      .blog .blog__element:nth-child(odd) .blog__img {
        order: 2; }
      .blog .blog__element:nth-child(odd) .blog__text {
        order: 1; }
        .blog .blog__element:nth-child(odd) .blog__text::before {
          right: -55px;
          left: auto;
          border: 30px solid transparent;
          border-left: 25px solid #e74c3c; }
      .blog .blog__element:nth-child(even) .blog__img {
        order: 1; }
      .blog .blog__element:nth-child(even) .blog__text {
        order: 2; }
        .blog .blog__element:nth-child(even) .blog__text::before {
          right: auto;
          left: -55px;
          border: 30px solid transparent;
          border-right: 25px solid #e74c3c; } }

@media (max-width: 580px) {
  .blog .blog__element:nth-child(odd) .blog__img, .blog .blog__element:nth-child(even) .blog__img {
    order: 1;
    flex-basis: 100%; }
    .blog .blog__element:nth-child(odd) .blog__img img, .blog .blog__element:nth-child(even) .blog__img img {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      height: auto; }
  .blog .blog__element:nth-child(odd) .blog__text, .blog .blog__element:nth-child(even) .blog__text {
    order: 2;
    flex-basis: 100%; }
    .blog .blog__element:nth-child(odd) .blog__text::before, .blog .blog__element:nth-child(even) .blog__text::before {
      display: none; } }

.keep {
  background-color: #f4f5f9;
  padding: 140px 0; }
  .keep p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #999999; }
  .keep .keep__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .keep .keep__devider p {
      text-align: center; }
      .keep .keep__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #e74c3c;
        display: block;
        border-radius: 50%; }
    .keep .keep__devider::after, .keep .keep__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .keep .keep__devider::before {
      left: -90px; }
    .keep .keep__devider::after {
      right: -90px; }
  .keep .keep__title {
    color: #6a6a6a;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .keep .keep__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px; }
  .keep .keep__left {
    flex-basis: 40.8%;
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2px/5px;
    padding: 30px 95px;
    box-sizing: border-box; }
  .keep .keep__right {
    flex-basis: 56.8%;
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px/5px;
    padding: 0 95px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .keep .keep__right .keep__form {
      flex-basis: 100%; }
  .keep .keep__info {
    margin: 55px 0; }
    .keep .keep__info p {
      text-align: left;
      color: #999999;
      font-size: 18px;
      line-height: 24px;
      margin: 20px 0; }
      .keep .keep__info p:first-child {
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        font-family: "Source Sans Pro";
        color: #e74c3c;
        text-transform: uppercase; }
      .keep .keep__info p a {
        color: #999999;
        font-size: 18px;
        line-height: 24px;
        text-decoration: none; }
  .keep .keep__form .keep__input, .keep .keep__form .keep__textarea {
    position: relative;
    margin: 20px 0; }
    .keep .keep__form .keep__input input, .keep .keep__form .keep__input textarea, .keep .keep__form .keep__textarea input, .keep .keep__form .keep__textarea textarea {
      color: #999999;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      font-weight: 600;
      border: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 7px 0;
      box-shadow: none; }
      .keep .keep__form .keep__input input:focus + label, .keep .keep__form .keep__input input:valid + label, .keep .keep__form .keep__input textarea:focus + label, .keep .keep__form .keep__input textarea:valid + label, .keep .keep__form .keep__textarea input:focus + label, .keep .keep__form .keep__textarea input:valid + label, .keep .keep__form .keep__textarea textarea:focus + label, .keep .keep__form .keep__textarea textarea:valid + label {
        transform: translateY(-9px);
        font-size: 12px; }
    .keep .keep__form .keep__input textarea, .keep .keep__form .keep__textarea textarea {
      resize: none;
      outline: none;
      height: 100px; }
    .keep .keep__form .keep__input label, .keep .keep__form .keep__textarea label {
      color: #999999;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition-duration: 200ms; }
  .keep .keep__form .keep__button {
    margin: 25px 0; }
    .keep .keep__form .keep__button button {
      width: 225px;
      border-radius: 3px;
      background-color: #e74c3c;
      border: 2px solid #e74c3c;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      padding: 18px 0;
      letter-spacing: 0.14px;
      text-decoration: none;
      display: inline-block;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      box-sizing: border-box;
      transition-duration: 500ms;
      transition-property: background-color, border-color;
      cursor: pointer;
      width: 157px;
      padding: 14px 0; }
      .keep .keep__form .keep__button button:hover {
        background-color: #d62c1a;
        border-color: #d62c1a; }

@media (max-width: 980px) {
  .keep {
    padding-bottom: 50px; }
    .keep .keep__left {
      padding: 30px; }
    .keep .keep__right {
      padding: 0 30px; } }

@media (max-width: 768px) {
  .keep .keep__info {
    margin: 25px 0; }
  .keep .keep__left {
    flex-basis: 100%;
    margin: 15px 0; }
  .keep .keep__right {
    flex-basis: 100%;
    margin: 15px 0;
    padding: 30px; } }

.free {
  background: url("../img/free-bg.jpg") center no-repeat;
  background-size: cover;
  padding: 140px 0;
  text-align: center;
  position: relative; }
  .free::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: block;
    z-index: 1; }
  .free .free__wrap {
    position: relative;
    z-index: 2; }
  .free p {
    color: #fff;
    font-size: 18px;
    line-height: 36px;
    margin: 12px 0; }
    .free p span {
      font-weight: 600; }
  .free .free__title {
    font-size: 36px; }
  .free .free__btn {
    width: 225px;
    border-radius: 3px;
    background-color: #e74c3c;
    border: 2px solid #e74c3c;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    padding: 18px 0;
    letter-spacing: 0.14px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    box-sizing: border-box;
    transition-duration: 500ms;
    transition-property: background-color, border-color;
    margin-top: 55px; }
    .free .free__btn:hover {
      background-color: #d62c1a;
      border-color: #d62c1a; }

@media (max-width: 980px) {
  .free {
    padding: 50px 0; } }

.footer {
  background-color: #202020;
  padding: 35px 0; }
  .footer .footer__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .footer .footer__right p {
    font-size: 14px;
    line-height: 24px;
    color: #999999;
    font-weight: 600; }

.socials {
  display: flex;
  align-items: center; }
  .socials li {
    margin-right: 20px; }
  .socials .socials__img {
    fill: #999999;
    width: 20px;
    height: 25px; }
    .socials .socials__img:hover {
      fill: #e74c3c; }

@media (max-width: 580px) {
  .footer .footer__wrap {
    justify-content: center;
    text-align: center; }
  .footer .footer__left {
    flex-basis: 100%; }
  .footer .footer__right {
    flex-basis: 100%; }
  .footer .footer__socials {
    justify-content: center; } }

.arrow-up {
  width: 53px;
  height: 53px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: #e74c3c;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10; }
  .arrow-up a {
    display: block;
    width: 100%;
    height: 100%; }
  .arrow-up img, .arrow-up svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .arrow-up .arrow-up__img {
    width: 12px;
    height: 12px;
    fill: #fff; }
