$white: #fff;
$black: #000;

$white-gray: #f4f5f9;
$gray-light: #cbc6c0;

$red: #e74c3c;

$advantages-line: #e6e7eb;
$advantages-border-gray: #e5e5e5;
$advantages-gray: #999999;

$gray: #6a6a6a;

$service-bg: #202020;