.section-wrap {
  position: relative;

  .section-wrap__icon {

    a {
      width: 64px;
      height: 64px;
      border: 5px solid rgba(0, 0, 0, 0.15);
      position: absolute;
      left: 50%;
      bottom: 8%;
      transform: translate(-50%, 15px);
      z-index: 2;
      border-radius: 50%;
      background-color: $white-gray;

      &:hover {

        .section-wrap__img {
          animation-name: rotate;
          animation-duration: 2s;
          animation-iteration-count: infinite;

        }

      }

    }

    .section-wrap__img {
      width: 30px;
      height: 30px;
      fill: $red;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

  }

}