.socials {
  display: flex;
  align-items: center;

  li {
    margin-right: 20px;
  }

  .socials__img {
    fill: $advantages-gray;
    width: 20px;
    height: 25px;

    &:hover {
      fill: $red;
    }

  }

}

@media (max-width: 580px) {

  .footer {

    .footer__wrap {
      justify-content: center;
      text-align: center;
    }

    .footer__left {
      flex-basis: 100%;
    }

    .footer__right {
      flex-basis: 100%;
    }

    .footer__socials {
      justify-content: center;
    }

  }

}