.free {
  background: url('../img/free-bg.jpg') center no-repeat;
  background-size: cover;
  padding: 140px 0;
  text-align: center;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: block;
    z-index: 1;
  }

  .free__wrap {
    position: relative;
    z-index: 2;
  }

  p {
    color: $white;
    font-size: 18px;
    line-height: 36px;
    margin: 12px 0;

    span {
      font-weight: 600;
    }

  }

  .free__title {
    font-size: 36px;
  }

  .free__btn {
    @include btn() {
      margin-top: 55px;
    };
  }

}

@media (max-width: 980px) {

  .free {
    padding: 50px 0;
  }

}