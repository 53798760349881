.buttons {
  margin-top: 60px;

  a {
    @include btn($bg: $red, $two-button: true);
  }

}

@media (max-width: 560px) {

  .buttons {

    a {
      display: block;
      margin: 10px auto;

      &:first-child {
        margin: 15px auto;
      }

    }

  }

}