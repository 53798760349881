.keep {
  background-color: $white-gray;
  padding: 140px 0;

  p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: $advantages-gray;
  }

  .keep__devider {
    @include devider($advantages-gray);
  }

  .keep__title {
    color: $gray;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase;
  }

  .keep__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;
  }

  .keep__left {
    flex-basis: 40.8%;
    background-color: $white;
    box-shadow: 0 3px 5px rgba(0,0,0,.1);
    border-radius: 2px/5px;
    padding: 30px 95px;
    box-sizing: border-box;
  }

  .keep__right {
    flex-basis: 56.8%;
    background-color: $white;
    box-shadow: 0 3px 5px rgba(0,0,0,.1);
    border-radius: 3px/5px;
    padding: 0 95px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .keep__form {
      flex-basis: 100%;
    }

  }

  .keep__info {
    margin: 55px 0;

    p {
      text-align: left;
      color: $advantages-gray;
      font-size: 18px;
      line-height: 24px;
      margin: 20px 0;
      
      &:first-child {
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        font-family: "Source Sans Pro";
        color: $red;
        text-transform: uppercase;
      }
      
      a {
        color: $advantages-gray;
        font-size: 18px;
        line-height: 24px;
        text-decoration: none;
      }
      
    }

  }

  .keep__form {

    .keep__input, .keep__textarea {
      position: relative;
      margin: 20px 0;

      input, textarea {
        color: $advantages-gray;
        font-size: 14px;
        line-height: 24px;
        width: 100%;
        font-weight: 600;
        border: none;
        border-bottom: 1px solid $advantages-border-gray;
        padding: 7px 0;
        box-shadow: none;

        &:focus + label, &:valid + label {
          transform: translateY(-9px);
          font-size: 12px;
        }

      }

      textarea {
        resize: none;
        outline: none;
        height: 100px;
      }

      label {
        color: $advantages-gray;
        font-size: 14px;
        line-height: 24px;
        width: 100%;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition-duration: 200ms;

      }

    }

    .keep__button {
      margin: 25px 0;

      button {
        @include btn() {
          cursor: pointer;
          width: 157px;
          padding: 14px 0;
        }
      }

    }

  }

}

@media (max-width: 980px) {

  .keep {
    padding-bottom: 50px;

    .keep__left {
      padding: 30px;
    }

    .keep__right {
      padding: 0 30px;
    }

  }

}

@media (max-width: 768px) {

  .keep {

    .keep__info {
      margin: 25px 0;
    }

    .keep__left {
      flex-basis: 100%;
      margin: 15px 0;
    }

    .keep__right {
      flex-basis: 100%;
      margin: 15px 0;
      padding: 30px;
    }

  }

}