@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -35%) rotate(-45deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  75% {
    transform: translate(-50%, -35%) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

}