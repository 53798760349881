.recent {
  margin-top: 140px;

  > p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: $advantages-gray;
  }

  .recent__devider {
    @include devider($advantages-gray);
  }

  .recent__title {
    color: $gray;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase;
  }

  .recent__links {
    margin: 40px 0;
    text-align: center;
    color: $advantages-gray;

    a {
      text-decoration: none;
      font-size: 14px;
      color: $advantages-gray;
      display: inline-block;
      margin: 5px 19px;
      transition-duration: 500ms;
      transition-property: color;

      &:hover {
        color: $red;
      }

    }

  }

  .recent__block {
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 40px auto 0;
  }

  .recent__element {
    flex-basis: 25%;
    position: relative;

    img {
      display: block;
      max-width: 100%;
    }

    a {
      text-decoration: none;
      display: block;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(231, 76, 60, 0.8);
        opacity: 0;
        transition-duration: 500ms;
        transition-property: opacity;
      }

      &:hover {

        &::after, p {
          opacity: 1;
        }

      }

    }

    p {
      position: absolute;
      left: 10%;
      bottom: 35px;
      z-index: 1;
      color: $white;
      opacity: 0;
      transition-duration: 500ms;
      transition-property: opacity;

      &::before {
        content: "";
        width: 44px;
        height: 4px;
        background-color: $white;
        margin-bottom: 25px;
        display: block;
      }

      span {
        display: block;
        margin: 15px 0;
        font-size: 14px;
        letter-spacing: 0.7px;

        &:first-child {
          text-transform: uppercase;
          font-family: "Source Sans Pro";
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 1.2px;
        }

      }

    }

  }

}

@media (max-width: 980px) {

  .recent {
    margin-top: 50px;

    .recent__element {

      p {
        bottom: 10px;

        span {

          &:first-child {
            font-size: 18px;
          }

        }

      }

    }

  }

}

@media (max-width: 768px) {

  .recent {

    .recent__element {
      flex-basis: 50%;
    }

  }

}

@media (max-width: 480px) {

  .recent {

    .recent__element {
      flex-basis: 100%;
    }

  }

}