.team {
  margin-top: 140px;
  padding: 140px 0 470px;
  background: url('../img/team-line.png') center bottom no-repeat,
              url('../img/team-bg.jpg') center no-repeat;

  .team__wrap {
    position: relative;
  }

  p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: $white-gray;
  }

  .team__devider {
    @include devider($white);
  }

  .team__title {
    color: $white;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase;
  }

  .team__man {
    position: absolute;
    width: 200px;

    &:hover {

      &::after {
        opacity: 1;
      }

      .team__img {
        transform: scale(1.3);

        &::after {
          opacity: 1;
        }

      }

      .team__info {
        z-index: 1;
        opacity: 1;
      }

    }

    &::after {
      content: "";
      width: 1px;
      height: 50px;
      display: block;
      background-color: $white;;
      position: absolute;
      left: 50%;
      bottom: -50px;
      transform: translate(-50%);
      opacity: 0;
      z-index: 1;
      transition-duration: 500ms;
      transition-property: opacity;
    }

    &:nth-of-type(1) {
      left: 0;
      bottom: -230px;
    }

    &:nth-of-type(2) {
      left: 265px;
      bottom: -195px;
    }

    &:nth-of-type(3) {
      left: 530px;
      bottom: -195px;
    }

    &:nth-of-type(4) {
      right: 190px;
      bottom: -230px;
    }

    &:nth-of-type(5) {
      right: 0px;
      bottom: -205px;
    }

  }

  .team__img {
    width: 51px;
    height: 51px;
    border: 3px solid $red;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 500ms;
    transition-property: transform;
    z-index: 2;
    position: relative;
    
    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      background-color: rgba(231, 76, 60, 0.5);
      opacity: 0;
      transition-duration: 500ms;
      transition-property: opacity;
    }
    

    img {
      display: block;
      max-width: 100%;
    }

  }

  .team__info {
    position: absolute;
    bottom: -160px;
    width: 100%;
    opacity: 0;
    z-index: -1;
    transition-duration: 500ms;
    transition-property: opacity;

    p {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1.4px;
      margin: 10px 0;
      font-family: "Source Sans Pro";

      &:first-of-type {
        font-size: 24px;
        line-height: 24px;
        color: $red;
        font-family: "Open Sans";
        font-weight: 600;
        letter-spacing: 1.8px;
      }

    }

  }

}

@media (max-width: 1080px) {

  .team {
    background: url('../img/team-bg.jpg') center no-repeat;
    background-size: cover;
    padding: 140px 0;

    .team__elements {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .team__man {
      position: relative;
      left: auto !important;
      right: auto !important;
      bottom: auto !important;
      width: auto;
      flex-basis: 20%;
      margin: 25px 0;

      &::after {
        display: none;
      }

    }

    .team__info {
      position: static;
      opacity: 1;
    }

  }


}

@media (max-width: 980px) {
  .team {
    padding: 50px 0;
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .team {

    .team__elements {
      justify-content: center;
    }

    .team__man {
      flex-basis: 45%;
    }

  }
}

@media (max-width: 480px) {
  .team {

    .team__man {
      flex-basis: 100%;
    }

  }
}